import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useNotify } from "react-admin";
// import DataProviders from "../../providers/dataProvider";

import DataProviders from "../providers/dataProvider";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const LawCategoryTable = () => {
  const classes = useStyles();
  const notify = useNotify();
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState({ id: null, name: "" });

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    DataProviders.getLawCategoryList()
      .then(({ data }) => {
        setCategories(data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        notify("Error fetching categories", "warning");
      });
  };

  const handleClickOpen = (data) => {
    setRowData(data || { id: null, name: "" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const apiCall = rowData.id
      ? DataProviders.updateLawCategory(rowData.id, { name: rowData.name })
      : DataProviders.createLawCategory({ name: rowData.name });

    apiCall
      .then(() => {
        notify(`Category ${rowData.id ? "updated" : "created"} successfully`);
        fetchCategories();
        handleClose();
      })
      .catch((error) => {
        console.error("Error saving category:", error);
        notify("Error saving category", "warning");
      });
  };

  const handleDelete = (id) => {
    DataProviders.deleteLawCategory(id)
      .then(() => {
        notify("Category deleted successfully");
        fetchCategories();
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
        notify("Error deleting category", "warning");
      });
  };

  return (
    <div>
      <Button
        startIcon={<AddIcon />}
        color="primary"
        onClick={() => handleClickOpen()}
      >
        New Category
      </Button>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">
                  <Button
                    startIcon={<CreateIcon />}
                    size="small"
                    color="primary"
                    onClick={() => handleClickOpen(row)}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    size="small"
                    color="secondary"
                    onClick={() => handleDelete(row.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          {rowData.id ? "Edit Category" : "Create Category"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={rowData.name}
            onChange={(event) =>
              setRowData({ ...rowData, name: event.target.value })
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LawCategoryTable;
