import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import Cookies from "js-cookie";

// const apiUrl = 'https://api.legalaide.co/api';
// const apiUrl = "https://legal-aide-api-dev.test/api";
// export const apiUrl = "http://192.168.100.232:8000/api";
export const apiUrl = "https://legal-api.minalindialysiscenter.com/api";
// export const apiUrl = "http://localhost:8000/api";
// export const apiUrl = "http://legal-aide-api.test/api";
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = Cookies.get("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const DataProviders = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = `&field=${field}&order=${order}&page=${page}&perPage=${perPage}`;
    let url;

    if (
      resource == "litigation" ||
      resource == "corporate" ||
      resource == "labor"
    ) {
      url = `${apiUrl}/filing-fee/manage/${resource}/filter?${stringify(
        params.filter
      )}&${query}`;
    } else if (resource == "filingfee") {
      url = `${apiUrl}/filing-fee/manage/filter?${stringify(
        params.filter
      )}&${query}`;
    } else if (resource == "filingtype") {
      url = `${apiUrl}/filing-fee/manage/parameter`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json.message.filingType,
        total: json.message.filingType.length,
      }));
    } else if (resource == "fee-percentage") {
      url = `${apiUrl}/setting/LEGAL_RESEARCH_FEE_PERCENTAGE`;

      return httpClient(url).then(({ headers, json }) => ({
        data: [
          {
            name: "LEGAL RESEARCH FEE PERCENTAGE",
            value: json.message,
          },
        ],
        total: 1,
      }));
    } else {
      url = `${apiUrl}/${resource}/filter?${stringify(params.filter)}`;
    }

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  getOne: (resource, params) => {
    let url;

    if (resource == "litigation") {
      url = `${apiUrl}/filing-fee/manage/${resource}/search/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: {
          ...json,
          court: json.courts.map((x) => x.id),
        },
      }));
    } else if (resource == "corporate") {
      url = `${apiUrl}/filing-fee/manage/${resource}/search/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: {
          ...json,
          category: json.categories.map((x) => x.id),
        },
      }));
    } else if (resource == "labor") {
      url = `${apiUrl}/filing-fee/manage/${resource}/search/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: {
          ...json,
        },
      }));
    } else if (resource == "filingfee") {
      url = `${apiUrl}/filing-fee/manage/search/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: {
          ...json,
          category: json.categories.map((x) => x.id),
          filing_type: json.filing_type.id,
        },
      }));
    } else if (resource == "place") {
      url = `${apiUrl}/${resource}/search/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: {
          ...json,
          place_type_id: json.place_type.id,
        },
      }));
    } else if (resource == "fee-percentage") {
      url = `${apiUrl}/setting/LEGAL_RESEARCH_FEE_PERCENTAGE`;

      return httpClient(url).then(({ headers, json }) => ({
        data: [
          {
            name: "LEGAL RESEARCH FEE PERCENTAGE",
            value: json.message,
          },
        ],
        total: 1,
      }));
    } else {
      url = `${apiUrl}/${resource}/search/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    }
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    let url = `${apiUrl}/${resource}/filter?${stringify(query)}`;
    if (resource == "filingtype") {
      url = `${apiUrl}/filing-fee/manage/parameter`;

      return httpClient(url).then(({ headers, json }) => ({
        data: json.message.filingType,
        total: json.message.filingType.length,
      }));
    }
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: (resource, params) => {
    if (resource === "place") {
      let formData = new FormData();
      Object.keys(params.data).forEach((key) =>
        formData.append(key, params.data[key])
      );

      if (params.data.newPictures) {
        for (let i = 0; i < params.data.newPictures.length; i++) {
          formData.append(
            `newPictures${i}`,
            params.data.newPictures[i].rawFile
          );
        }
      }

      formData.delete("newPictures");

      return httpClient(`${apiUrl}/${resource}/edit/${params.id}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({ data: json }));
    } else {
      if (resource == "filingfee") {
        if (params.data.amountType != "CONDITIONAL") {
          delete params.data.conditionalTable;
        } else if (params.data.amountType != "FORMULA") {
          delete params.data.formula;
        } else if (
          params.data.amountType != "AMOUNT_ONLY" &&
          params.data.amountType != "PER_AMOUNT"
        ) {
          delete params.data.amount;
        }

        return httpClient(`${apiUrl}/filing-fee/manage/edit/${params.id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
      } else if (resource == "fee-percentage") {
        return httpClient(`${apiUrl}/setting/LEGAL_RESEARCH_FEE_PERCENTAGE`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
      } else {
        return httpClient(`${apiUrl}/${resource}/edit/${params.id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          // data: json,
          data: { ...params.data, id: json.id },
        }));
      }
    }
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    if (resource === "place") {
      let formData = new FormData();
      Object.keys(params.data).forEach((key) =>
        formData.append(key, params.data[key])
      );

      if (params.data.pictures) {
        for (let i = 0; i < params.data.pictures.length; i++) {
          formData.append(`pictures${i}`, params.data.pictures[i].rawFile);
        }
      }

      formData.delete("pictures");

      return httpClient(`${apiUrl}/${resource}/create`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    } else {
      if (resource == "filingfee") {
        if (params.data.amountType != "CONDITIONAL") {
          delete params.data.conditionalTable;
        } else if (params.data.amountType != "FORMULA") {
          delete params.data.formula;
        } else if (
          params.data.amountType != "AMOUNT_ONLY" &&
          params.data.amountType != "PER_AMOUNT"
        ) {
          delete params.data.amount;
        }

        return httpClient(`${apiUrl}/filing-fee/manage/create`, {
          method: "POST",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }));
      } else {
        return httpClient(`${apiUrl}/${resource}/create`, {
          method: "POST",
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }));
      }
    }
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/delete/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  activate: (resource, params) => {
    if (resource == "Filingfee") {
      resource = `filing-fee/manage`;
    }
    return httpClient(`${apiUrl}/${resource}/activate/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  deactivate: (resource, params) => {
    if (resource == "Filingfee") {
      resource = `filing-fee/manage`;
    }
    return httpClient(`${apiUrl}/${resource}/deactivate/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  resetPassword: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/password-reset/${params.id}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  // For category list (dropdown) inside law & jurisprudence creation
  getLawCategoryList: () => {
    return httpClient(`${apiUrl}/law-category`).then(({ json }) => ({
      data: json,
    }));
  },

  createLawCategory: (data) => {
    return httpClient(`${apiUrl}/law-category/create`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  updateLawCategory: (id, data) => {
    return httpClient(`${apiUrl}/law-category/edit/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }));
  },

  deleteLawCategory: (id) => {
    return httpClient(`${apiUrl}/law-category/delete/${id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },
};

export default DataProviders;
