import * as React from "react";
import { Admin, Resource, ListGuesser, EditGuesser } from "react-admin";
import Dashboard from "./Dashboard";
import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";

import { PlanList, PlanEdit, PlanCreate } from "./components/resource/plans";
import { UserList, UserEdit, UserCreate } from "./components/resource/users";
import { SubscriptionList } from "./components/resource/subscriptions";
import { AreaList, AreaEdit, AreaCreate } from "./components/resource/areas";
import { LawList, LawEdit, LawCreate } from "./components/resource/laws";
import {
  PlaceList,
  PlaceEdit,
  PlaceCreate,
} from "./components/resource/places";
import {
  NotificationList,
  NotificationCreate,
} from "./components/resource/notifications";
import {
  ChecklistList,
  ChecklistEdit,
  ChecklistCreate,
} from "./components/resource/checklists";
import {
  LitigationList,
  LitigationEdit,
  LitigationCreate,
} from "./components/resource/litigation";
import {
  CorporateList,
  CorporateEdit,
  CorporateCreate,
} from "./components/resource/corporate";
import { LaborList, LaborEdit, LaborCreate } from "./components/resource/labor";
import {
  CourtList,
  CourtEdit,
  CourtCreate,
} from "./components/resource/courts";
import {
  CategoryList,
  CategoryEdit,
  CategoryCreate,
} from "./components/resource/categories";
import {
  FilingfeeList,
  FilingfeeEdit,
  FilingfeeCreate,
} from "./components/resource/filingfee";
import {
  FilingtypeList,
  FilingtypeEdit,
  FilingtypeCreate,
} from "./components/resource/filingtype";
import {
  PlacetypeList,
  PlacetypeEdit,
  PlacetypeCreate,
} from "./components/resource/placetype";
import {
  FeepercentageList,
  FeepercentageEdit,
} from "./components/resource/feepercentage";

import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import BookIcon from "@material-ui/icons/Book";
import UserIcon from "@material-ui/icons/Group";
import SubscriptionIcon from "@material-ui/icons/Receipt";
import WorkIcon from "@material-ui/icons/Work";
import NoteIcon from "@material-ui/icons/Note";
import PlaceIcon from "@material-ui/icons/Place";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DescriptionIcon from "@material-ui/icons/Description";

import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import CustomLayout from "./components/CustomLayout";
import { Route } from "react-router-dom";
import LawCategoryTable from "./components/LawCategoryTable";

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

const theme = createMuiTheme({
  sidebar: {
    closedWidth: 65,
  },
  palette: {
    primary: {
      main: indigo[700],
    },
    secondary: {
      main: indigo[700],
    },
    error: {
      main: red[700],
    },
    success: {
      main: green[700],
    },
  },
});

const App = () => {
  return (
    <Admin
      layout={CustomLayout}
      theme={theme}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      locale="en"
      i18nProvider={i18nProvider}
      // customRoutes={[
      //   <Route exact path="/law-category" component={LawCategoryTable} />,
      // ]}
    >
      {(permissions) => [
        permissions["read plan"] ? (
          <Resource
            name="plan"
            list={PlanList}
            create={permissions["write plan"] ? PlanCreate : null}
            edit={permissions["write plan"] ? PlanEdit : null}
            icon={NoteIcon}
          />
        ) : null,
        permissions["read user"] ? (
          <Resource
            name="user"
            list={UserList}
            create={permissions["write user"] ? UserCreate : null}
            edit={permissions["write user"] ? UserEdit : null}
            icon={UserIcon}
          />
        ) : null,
        permissions["read subscription"] ? (
          <Resource
            name="subscription"
            list={SubscriptionList}
            icon={SubscriptionIcon}
          />
        ) : null,
        permissions["read law"] ? (
          <Resource
            name="area"
            list={AreaList}
            create={permissions["write law"] ? AreaCreate : null}
            edit={permissions["write law"] ? AreaEdit : null}
            icon={WorkIcon}
          />
        ) : null,
        permissions["read law"] ? (
          <Resource
            name="law"
            list={LawList}
            create={permissions["write law"] ? LawCreate : null}
            edit={permissions["write law"] ? LawEdit : null}
            icon={BookIcon}
          />
        ) : null,
        permissions["read law"] ? (
          <Resource
            name="Law Categorie"
            list={LawCategoryTable}
            // create={permissions["write law"] ? LawCreate : null}
            icon={BookIcon}
          />
        ) : null,
        permissions["read place"] ? (
          <Resource
            name="place"
            list={PlaceList}
            create={permissions["write place"] ? PlaceCreate : null}
            edit={permissions["write place"] ? PlaceEdit : null}
            icon={PlaceIcon}
          />
        ) : null,
        permissions["read place"] ? (
          <Resource
            name="place-type"
            list={PlacetypeList}
            create={permissions["write place"] ? PlacetypeCreate : null}
            edit={permissions["write place"] ? PlacetypeEdit : null}
            icon={PlaceIcon}
          />
        ) : null,
        permissions["read notification"] ? (
          <Resource
            name="notification"
            list={NotificationList}
            create={
              permissions["write notification"] ? NotificationCreate : null
            }
            icon={NotificationsIcon}
          />
        ) : null,
        permissions["read checklist"] ? (
          <Resource
            name="checklist"
            list={ChecklistList}
            create={permissions["write checklist"] ? ChecklistCreate : null}
            edit={permissions["write checklist"] ? ChecklistEdit : null}
            icon={AssignmentTurnedInIcon}
          />
        ) : null,
        permissions["read filing fee"] ? (
          <Resource
            name="litigation"
            list={LitigationList}
            create={permissions["write filing fee"] ? LitigationCreate : null}
            edit={permissions["write filing fee"] ? LitigationEdit : null}
            icon={DescriptionIcon}
          />
        ) : null,
        permissions["read filing fee"] ? (
          <Resource
            name="corporate"
            list={CorporateList}
            create={permissions["write filing fee"] ? CorporateCreate : null}
            edit={permissions["write filing fee"] ? CorporateEdit : null}
            icon={DescriptionIcon}
          />
        ) : null,
        permissions["read filing fee"] ? (
          <Resource
            name="filingfee"
            list={FilingfeeList}
            create={permissions["write filing fee"] ? FilingfeeCreate : null}
            edit={permissions["write filing fee"] ? FilingfeeEdit : null}
            icon={DescriptionIcon}
          />
        ) : null,
        permissions["read filing fee"] ? (
          <Resource
            name="category"
            list={CategoryList}
            create={permissions["write filing fee"] ? CategoryCreate : null}
            edit={permissions["write filing fee"] ? CategoryEdit : null}
            icon={DescriptionIcon}
          />
        ) : null,
        permissions["read filing fee"] ? (
          <Resource
            name="filing-type"
            list={FilingtypeList}
            create={permissions["write filing fee"] ? FilingtypeCreate : null}
            edit={permissions["write filing fee"] ? FilingtypeEdit : null}
            icon={DescriptionIcon}
          />
        ) : null,
        permissions["read filing fee"] ? (
          <Resource
            name="fee-percentage"
            list={FeepercentageList}
            edit={permissions["write filing fee"] ? FeepercentageEdit : null}
            icon={DescriptionIcon}
          />
        ) : null,
        /* permissions['read filing fee'] ? 
        <Resource name="labor" list={LaborList} create={permissions['write filing fee'] ? LaborCreate : null} edit={permissions['write filing fee'] ? LaborEdit : null} icon={DescriptionIcon} /> : null,
      permissions['read filing fee'] ? 
        <Resource name="court" list={CourtList} create={permissions['write filing fee'] ? CourtCreate : null} edit={permissions['write filing fee'] ? CourtEdit : null} icon={DescriptionIcon} /> : null, */
      ]}
    </Admin>
  );
};

export default App;
